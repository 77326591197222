import React, { useState, useEffect, useContext } from 'react';

const defaultState = {
  loading: false,
  token: '',
  authenticated: false,
  login: (indentifier, password) => null,
  checkAuthentication: () => null,
  logout: () => null,
  error: null
}


const AuthContext = React.createContext(defaultState);

export const useAuth = () => useContext(AuthContext);

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = ({ children }) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null)
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState('');
  
  const login = async (identifier, password) => {
    // Check if already logged in and log out if so
    if (authenticated) logout();

    setLoading(true);

    // Set body
    let urlencoded = new URLSearchParams();
    urlencoded.append("identifier", identifier);
    urlencoded.append("password", password);

    const response = await fetch(
      `${process.env.GATSBY_API_URL}/auth/local`, {
        method: 'POST',
        body: urlencoded,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      }
    );

    // Set token and save to localstorage
    if (response.ok) {
      const data = await response.json();
      setToken(data.jwt);
      setAuthenticated(true);
      localStorage.setItem('token', data.jwt);
    } else {
      let error = new Error();
      error = {...error, ...response};
      setError(error);
    }

    setLoading(false);
    return response.ok;
  }

  const logout = () => {
    setToken('');
    setAuthenticated(false);
    setError(null);
    localStorage.removeItem('token');
  }

  const checkAuthentication = () => {
    if (authenticated && token !== '') return token;
    const storedToken = localStorage.getItem('token');
    console.log(storedToken);
    if (storedToken) {
      setToken(storedToken);
      setAuthenticated(true);
      return storedToken;
    }
    return false;
  }

  useEffect(() => {
    // Check if user is logged in
    checkAuthentication();
  }, []);


  return(
    <AuthContext.Provider
      value={{
        loading: loading,
        token: token,
        authenticated: authenticated,
        login: login,
        logout: logout,
        checkAuthentication: checkAuthentication,
        error: error
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;
